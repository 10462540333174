@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
$black: #000000;
$white: #ffffff;
$darkGrey: #8d909c;
$lightGrey: #e9e9ec;
$bgLightGrey: #f3f4f5;
$red: #d00000;
$green: #00956c;
$orange: #08ad18;

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    h3 {
      margin-bottom: 10px;
    }
    input {
      margin-bottom: 10px;
      padding: 7.5px 15px;
      width: 100%;
      border: 1px solid $darkGrey;
      border-radius: 5px;
      outline: none;
    }
    button {
      border-radius: 5px;
      margin-top: 10px;
      width: 50%;
      background-color: $red;
      padding: 8px 0;
      color: white;
      border: none;
      outline: none;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    ul {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 300px;
      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid $darkGrey;
        padding-bottom: 10px;
        margin-bottom: 10px;
        p {
          margin-top: 20px;
          background-color: $lightGrey;
          padding: 5px 35px;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
