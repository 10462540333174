@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100vh;
}

.App form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 300px;
}

.App form h3 {
  margin-bottom: 10px;
}

.App form input {
  margin-bottom: 10px;
  padding: 7.5px 15px;
  width: 100%;
  border: 1px solid #8d909c;
  border-radius: 5px;
  outline: none;
}

.App form button {
  border-radius: 5px;
  margin-top: 10px;
  width: 50%;
  background-color: #d00000;
  padding: 8px 0;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

.App form button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.App .list {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
}

.App .list ul {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 300px;
}

.App .list ul li {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-bottom: 1px solid #8d909c;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.App .list ul li p {
  margin-top: 20px;
  background-color: #e9e9ec;
  padding: 5px 35px;
  border-radius: 5px;
  cursor: pointer;
}

.App .list ul li p:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
